import { render, staticRenderFns } from "./CardChartRevenue.vue?vue&type=template&id=454a08fa&"
import script from "./CardChartRevenue.vue?vue&type=script&lang=js&"
export * from "./CardChartRevenue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('454a08fa')) {
      api.createRecord('454a08fa', component.options)
    } else {
      api.reload('454a08fa', component.options)
    }
    module.hot.accept("./CardChartRevenue.vue?vue&type=template&id=454a08fa&", function () {
      api.rerender('454a08fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Dashboard/Widgets/CardChartRevenue.vue"
export default component.exports