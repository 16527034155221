var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl px-4 py-4" },
    [
      _c("h6", { staticClass: "mb-4 text-h6 text-typo font-weight-bold" }, [
        _vm._v("Sales by Age"),
      ]),
      _c("div", { staticClass: "chart-area" }, [
        _c("canvas", { attrs: { height: 340, id: _vm.salesAgeChartID } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }