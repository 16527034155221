var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.stats, function (item, i) {
                      return _c(
                        "v-col",
                        { key: item.text + i, attrs: { sm: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-shadow border-radius-xl py-4",
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "px-4",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c("v-col", { attrs: { sm: "4" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "\n                      text-sm\n                      mb-1\n                      text-capitalize text-body\n                      font-weight-bold\n                    ",
                                      },
                                      [_vm._v(" " + _vm._s(item.text) + " ")]
                                    ),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-h5 text-typo font-weight-bolder mb-0",
                                      },
                                      [_vm._v(" " + _vm._s(item.value) + " ")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "8" },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            transition: "slide-x-transition",
                                            "offset-y": "",
                                            "offset-x": "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "text-capitalize px-0 text-xs",
                                                            attrs: {
                                                              text: "",
                                                              ripple: false,
                                                              small: "",
                                                              elevation: "0",
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-xs text-secondary font-weight-light",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "6 May - 7 May"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "pa-2" },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active border-radius-md",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-light mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Last 7 days "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active border-radius-md",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-light mb-0",
                                                        },
                                                        [_vm._v(" Last week ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active border-radius-md",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-light mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Last 30 days "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("hr", {
                                                staticClass: "horizontal dark",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4 mt-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "mb-0 text-body text-sm",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-success text-sm font-weight-bolder",
                                            },
                                            [_vm._v(_vm._s(item.growth))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-light ms-1",
                                            },
                                            [_vm._v("since last month")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [_c("card-chart-channels")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [_c("card-chart-revenue")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [_c("card-chart-sales-age")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl px-4 py-4" },
                    [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "mb-0 text-h6 text-typo font-weight-bold",
                        },
                        [_vm._v(" Sales by Country ")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "pt-3 pb-0 px-0" },
                        [
                          _c(
                            "v-list",
                            { staticClass: "py-0" },
                            _vm._l(_vm.countries, function (chat, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: chat.title,
                                  staticClass: "pt-2 pb-1 px-1",
                                  class: {
                                    "border-bottom":
                                      index != _vm.countries.length - 1,
                                  },
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "me-6",
                                    attrs: {
                                      alt: chat.title + " avatar",
                                      src: chat.avatar,
                                      "max-width": "20px",
                                    },
                                  }),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-xs font-weight-bold text-body",
                                        },
                                        [_vm._v(" Country: ")]
                                      ),
                                      _c("v-list-item-title", {
                                        staticClass: "text-typo text-sm mb-0",
                                        domProps: {
                                          textContent: _vm._s(chat.country),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-xs font-weight-bold text-body",
                                        },
                                        [_vm._v(" Sales: ")]
                                      ),
                                      _c("v-list-item-title", {
                                        staticClass: "text-typo text-sm mb-0",
                                        domProps: {
                                          textContent: _vm._s(chat.sales),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-xs font-weight-bold text-body",
                                        },
                                        [_vm._v(" Bounce: ")]
                                      ),
                                      _c("v-list-item-title", {
                                        staticClass: "text-typo text-sm mb-0",
                                        domProps: {
                                          textContent: _vm._s(chat.bounce),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl py-4" },
                    [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "mb-0 text-h6 text-typo font-weight-bold px-4",
                        },
                        [_vm._v(" Top Selling Products ")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "pt-3 pb-0 px-0" },
                        [
                          _c("v-simple-table", {
                            staticClass: "table",
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "\n                        text-left text-uppercase text-secondary text-xxs\n                        font-weight-bolder\n                        opacity-7\n                      ",
                                          },
                                          [_vm._v(" Product ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "\n                        text-left text-uppercase text-secondary text-xxs\n                        font-weight-bolder\n                        opacity-7\n                      ",
                                          },
                                          [_vm._v(" Value ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "\n                        text-center text-uppercase text-secondary text-xxs\n                        font-weight-bolder\n                        opacity-7\n                      ",
                                          },
                                          [_vm._v(" Ads spent ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "\n                        text-center text-uppercase text-secondary text-xxs\n                        font-weight-bolder\n                        opacity-7\n                      ",
                                          },
                                          [_vm._v(" Refunds ")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.products, function (item) {
                                        return _c("tr", { key: item.refunds }, [
                                          _c(
                                            "td",
                                            { staticClass: "border-bottom" },
                                            [
                                              _c(
                                                "v-list",
                                                { staticClass: "py-0" },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass: "px-0 py-4",
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-avatar",
                                                        {
                                                          staticClass:
                                                            "my-0 me-4 border-rounded",
                                                          attrs: { size: 48 },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              alt: item.imgSrc,
                                                              src: item.imgSrc,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "\n                                text-typo text-subtitle-2\n                                ls-0\n                                font-weight-bold\n                              ",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.product
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-secondary text-sm",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-success",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.orders
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" orders"),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "border-bottom" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-sm text-secondary mb-0",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.value) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-body border-bottom text-center",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-sm text-secondary mb-0",
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.ads) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "border-bottom text-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "\n                          d-flex\n                          align-items-center\n                          justify-content-center\n                        ",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-secondary text-sm ms-auto",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.refunds)
                                                      ),
                                                    ]
                                                  ),
                                                  item.refunds <= 40
                                                    ? _c("i", {
                                                        staticClass:
                                                          "\n                            ni ni-bold-down\n                            text-sm\n                            ms-1\n                            text-success\n                            me-auto\n                          ",
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "\n                            ni ni-bold-up\n                            text-sm\n                            ms-1\n                            text-danger\n                            me-auto\n                          ",
                                                      }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }