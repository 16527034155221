var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl px-4 py-4" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c("h6", { staticClass: "mb-0 text-h6 text-typo font-weight-bold" }, [
            _vm._v("Channels"),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "\n            font-weight-600\n            text-capitalize\n            btn-outline-secondary\n            pa-1\n            ms-auto\n          ",
                              attrs: {
                                small: "",
                                rounded: "",
                                "min-width": "25",
                                elevation: "0",
                                ripple: false,
                                height: "25",
                                width: "25",
                                color: "transparent",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-icons-round",
                              attrs: { size: "14" },
                            },
                            [_vm._v("priority_high")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("See traffic channels")])]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 py-3" },
        [
          _c("v-col", { staticClass: "text-start", attrs: { cols: "7" } }, [
            _c("div", { staticClass: "chart-area" }, [
              _c("canvas", { attrs: { height: 200, id: _vm.channelsChartID } }),
            ]),
          ]),
          _c("v-col", { staticClass: "my-auto", attrs: { cols: "5" } }, [
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#1a73e8",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-xs ms-3" }, [
                  _vm._v("Facebook"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#e91e63",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-xs ms-3" }, [
                  _vm._v("Direct"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#344767",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-xs ms-3" }, [
                  _vm._v("Organic"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#7b809a",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-xs ms-3" }, [
                  _vm._v("Referral"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex text-body mt-8" },
        [
          _c("p", { staticClass: "text-sm font-weight-light mb-0 w-60" }, [
            _vm._v(" More than "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("1,200,000"),
            ]),
            _vm._v(" sales are made using referral marketing, and "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("700,000"),
            ]),
            _vm._v(" are from social media. "),
          ]),
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-body bg-lighter py-5 px-6 my-auto ms-auto",
              attrs: { elevation: 0, color: "#cb0c9f", small: "" },
            },
            [_vm._v(" Read more ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }