var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl px-4 py-4" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c("h6", { staticClass: "mb-0 text-h6 text-typo font-weight-bold" }, [
            _vm._v("Revenue"),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "\n            font-weight-600\n            text-capitalize\n            btn-outline-secondary\n            pa-1\n            ms-auto\n          ",
                              attrs: {
                                small: "",
                                rounded: "",
                                "min-width": "25",
                                elevation: "0",
                                ripple: false,
                                height: "25",
                                width: "25",
                                color: "transparent",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-icons-round",
                              attrs: { size: "14" },
                            },
                            [_vm._v("priority_high")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("See which ads perform better")])]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "my-1" },
          [
            _c("v-badge", {
              attrs: {
                bottom: "",
                bordered: "",
                color: "#e91e63",
                dot: "",
                "offset-x": "0",
                "offset-y": "9",
              },
            }),
            _c("span", { staticClass: "text-dark text-xs ms-4" }, [
              _vm._v("Facebook Ads"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "my-1 ms-6" },
          [
            _c("v-badge", {
              attrs: {
                bottom: "",
                bordered: "",
                color: "#344767",
                dot: "",
                "offset-x": "0",
                "offset-y": "9",
              },
            }),
            _c("span", { staticClass: "text-dark text-xs ms-4" }, [
              _vm._v("Google Ads"),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "chart-area mt-4" }, [
        _c("canvas", { attrs: { height: 300, id: _vm.revenueChartID } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }